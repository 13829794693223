<template>
  <!-- 产品-开智脑波 -->
  <div class="website-css Product-WLZG mobile-css">
    <!-- 请在浏览器打开 beg-->
    <div class="QuestionsTipsPop" v-show="PopTips" @touchmove.stop.prevent>
      <img
          class="download-zhezhao"
          src="https://eletell.oss-cn-beijing.aliyuncs.com/H5_Activity/TheBrainMatch/zhezhao.png"
      />
    </div>
    <!-- 请在了浏览器打开 end -->
    <button class="dowWLZG" @click="downloadAPP(4)" v-show="showCode" type="">
      下载开智脑波
    </button>
    <!-- banner beg -->
    <div class="Solution-banner AboutUs-banner">
      <div class="w1200">
        <img
            src="../assets/image/Website-img/dtx_icon_word.png"
            class="wlzg-banner-icon wlzg-banner-logo"
        />
        <img
            src="../assets/image/Website-img/dtx-banner-2.png"
            class="wlzg-banner-icon wlzg-banner-icon4 animated slideInRight"
        />

        <img
            src="../assets/image/Website-img/dtx-banner-1.png"
            class="wlzg-banner-icon wlzg-banner-icon1 animated slideInLeft"
        />

        <div class="banner-title" style="padding-top: 100px;">
          <p style="width: 75%">{{ this.languageList.bannertitle }}</p>
          <p style="width: 75%">{{ this.languageList.bannerContent }}</p>
          <!--          <img-->
          <!--            class="wlzg-banner-ewm animated fadeInDown"-->
          <!--            src="../assets/image/Website-img/wlzg-ewm.png"-->
          <!--          />-->
          <!--          <p class="ewm-text">{{ this.languageList.bannerContent1 }}</p>-->
          <div class="Distributor" v-show="showCode">
            <p>分销员密令</p>
            <p>
              {{
                this.code
              }}<span
                v-clipboard:copy="this.code"
                v-clipboard:success="copy"
                v-clipboard:error="onError"
            >复制</span
            >
            </p>
          </div>
        </div>
        <!-- <img src="../assets/image/Website-img/bannericon1.png" class="banner-icon3" /> -->
      </div>
    </div>
    <!-- banner end -->

    <!--模块一 AI疗愈 beg-->
    <div class="w1200" style="z-index: 100">
      <div style="width: 100%;color: #000000;font-size: 35px;text-align: center;padding-top: 60px">
        <p><span></span>{{ this.languageList.list[0].title }}</p>
      </div>
      <div style="font-size: 18px;line-height: 30px;padding-left: 30px;padding-right: 30px;padding-top: 40px">
        <p>
          {{ this.languageList.list[0].content }}
        </p>
      </div>
      <div style="text-align: center;padding-top: 40px;">
        <img :src="this.languageList.list[0].img" class="product1" style="width: 35%"/>
      </div>
    </div>
    <!--模块一 AI疗愈 end-->

    <!--模块二 趣味减压 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img :src="this.languageList.list[1].img" class="product2"/>
          </div>
          <div class="wlzg-box-text text-right">
            <p><span></span>{{ this.languageList.list[1].title }}</p>
            <p>
              {{ this.languageList.list[1].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块二 趣味减压 beg-->

    <!--模块三 解忧社区 beg-->
    <div class="w1200">
      <div class="wlzg-box">
        <div class="wlzg-box-text">
          <p><span></span>{{ this.languageList.list[2].title }}</p>
          <p>
            {{ this.languageList.list[2].content }}
          </p>
        </div>
        <div class="wlzg-box-img">
          <img :src="this.languageList.list[2].img" class="product1"/>
        </div>
      </div>
    </div>
    <!--模块三 解忧社区 end-->

    <!--模块四 情绪管理 beg-->
    <div class="Enterprise-bg-f8">
      <div class="w1200">
        <div class="wlzg-box">
          <div class="wlzg-box-img">
            <img :src="this.languageList.list[3].img" class="product2"/>
          </div>
          <div class="wlzg-box-text text-right">
            <p><span></span>{{ this.languageList.list[3].title }}</p>
            <p>
              {{ this.languageList.list[3].content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--模块四 情绪管理 beg-->

    <web-bottom ref="child"></web-bottom>
  </div>
</template>

<script>
import WebBottom from '../components/WebBottom'
import ChineseJson from '../assets/json/Chinese-Json.json'
import EnglishJson from '../assets/json/English-Json.json'
import Function from '../assets/js/function'

export default {
  metaInfo: {
    title: '开智脑波-北京大象智酷科技有限公司', // set a title
    meta: [
      // set meta
      {
        name: 'keywords',
        content: '开智脑波,提升脑力,能力提升,可视化数据,脑力训练',
      },
      {
        name: 'description',
        content:
            '多领域任务,针对性训练,科学理论转化,可视化数据,综合记忆、逻辑、反应、专注、计算和想象等能力对训练计划进行组合,让大脑在多领域任务中得到全面提升,使训练效果更好地迁移至日常生活中.以不同年龄段及人群认知发展规律和能力提升重点为理论、任务设置、算法等基础,同时根据训练者不同需求和任务表现对训练方案难度进行自动调整,使训练更加具有针对性.',
      },
    ],
    // link: [{                 // set link
    //  		rel: 'asstes',
    //   	href: 'https://assets-cdn.github.com/'
    // }]
  },
  name: 'Product-XQTX',
  components: {
    WebBottom,
  },
  data() {
    return {
      ChineseJson: JSON.parse(JSON.stringify(ChineseJson)), //中文配表
      EnglishJson: JSON.parse(JSON.stringify(EnglishJson)), //英文配表
      languageJson: [],
      languageList: [],
      code: this.$route.query.code,
      msgs: '',
      showCode: false,
      PopTips: false,
    }
  },
  props: {
    msg: String,
  },
  created() {
    // this.langtype = localStorage.getItem("LanguageTypes");
    // console.log("语言类型", this.langtype);
    // this.IFType();
    if (localStorage.getItem('LanguageTypes') == undefined) {
      this.langtype = 1
      this.IFType()
    } else {
      this.langtype = localStorage.getItem('LanguageTypes')
      this.IFType()
    }
  },
  mounted() {
    if (this.code) {
      this.showCode = true
    } else {
      this.showCode = false
    }
    const link = document.getElementById('dynamic_favicon');
    link.href = '/dtx.ico';
  },
  methods: {
    // APP下载按钮
    downloadAPP(BtnType) {
      switch (BtnType) {
        case 1: {
          Function.downloadAPP()
          break
        }
        case 2: {
          Function.downloadBsydApp()
          break
        }
        case 3: {
          Function.downloadXxnlApp()
          break
        }
        case 4: {
          this.isWeiXin()
          break
        }
      }
    },

    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
          Function.downloadDistributorAPP()
        } else {
          this.PopTips = true
          Function.downloadDistributorAPP()
        }

        return true // 是微信端
      } else {
        // this.PopTips = true
        Function.downloadDistributorAPP()
        return false
      }
    },

    //点击复制粘贴
    copy(e) {
      this.OnOnlineStatus()
      this.msgs = this.$message({
        message: e.text + '已复制',
        type: 'success',
      })
    },
    onError() {
    },
    copyActiveCode() {
      const clipboard = new Clipboard('#tag-copy')
      clipboard.on('success', (e) => {
        this.msgs = this.$message({type: 'success', message: '复制成功'})
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.msgs = this.$message({
          type: 'waning',
          message: '该浏览器不支持自动复制',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    OnOnlineStatus() {
      this.msgs ? this.msgs.close() : ''
    },
    IFType() {
      if (this.langtype == 1) {
        this.languageJson = this.ChineseJson
        this.getList()
      } else {
        this.languageJson = this.EnglishJson
        this.getList()
      }
    },
    getMsgFormSon(data) {
      // this.msgFormSon = data;
      this.langtype = data
      this.IFType()
      this.$refs.child.sing(this.langtype)
    },
    getList() {
      // this.ChineseJson.fo
      this.languageJson.forEach((item) => {
        if (item.title == 'Product-DTx') {
          this.languageList = item.List[0]
        }
      })
    },
  },
}
</script>
<style scoped>
.AboutUs-banner {
  width: 100%;
  height: 715px;
  position: relative;
  background: linear-gradient(to bottom, #2BB799, #B7EDCC);
}

.wlzg-box-text p:nth-of-type(1) span {
  background: linear-gradient(to bottom, #2BB799, #C3EACA);
  display: block;
  width: 5px;
  border-radius: 30px;
  height: 36px;
  float: left;
  margin-right: 16px;
}

.Solution-banner {
}

.brain-ul li {
  float: left;
  width: 400px;
  height: 300px;
}

.wlzg-box-img {
  width: 50%;
}

.wlzg-box-img img {
  width: 50%;
}


.wlzg-banner-icon1 {
  width: 18%;
  bottom: 0;
  right: 10%;
  opacity: 1;
}

.wlzg-banner-icon4 {
  width: 18%;
  bottom: 0;
  right: 1%;
  opacity: 1;
}
</style>